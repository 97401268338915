/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.06.21 : usayama) // LoginFormContainer に変更
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import LoginFormContainer from '@containers/LoginFormContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.06.21 : usayama) // LoginFormContainer に変更
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
 */
const Login = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   */
	return (
    <LayoutContainer>
      <Seo title={t('title.login')} />
		  <LoginFormContainer />
	 </LayoutContainer>
  )
}

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
export default Login
